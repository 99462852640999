export default {
  data () {
    return {}
  },
  methods: {
    initialize () {},
    goToRouteFirst () {
      this.$router.push({ path: 'billing-report' }).catch(() => {})
    }
  }
}
